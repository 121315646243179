import React from "react";
import { format, parse } from "date-fns";
import useClient from "hooks/useClient";
import { MdRemoveRedEye } from "react-icons/md";

const MessageHistory = () => {
  const { getMessageHistory } = useClient();

  const [messageHistory, setMessageHistory] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");

  const [selectedMessage, setSelectedMessage] = React.useState(null);

  const maxPageButtons = 5;
  const halfMaxButtons = Math.floor(maxPageButtons / 2);
  const startPage = Math.max(1, page - halfMaxButtons);
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  const handleMessageHistory = async () => {
    const {
      messageHistory,
      page: currentPage,
      totalPages,
    } = await getMessageHistory(page, startDate, endDate);
    setMessageHistory(messageHistory);
    setPage(currentPage);
    setTotalPages(totalPages);
  };

  React.useEffect(() => {
    handleMessageHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, startDate, endDate]);

  const formatAndTruncate = (text, maxLength) => {
    let previewText = text
      .replace(/\*(.*?)\*/g, "<b>$1</b>")
      .replace(/_(.*?)_/g, "<i>$1</i>")
      .replace(/~(.*?)~/g, "<s>$1</s>")
      .replace(/`(.*?)`/g, "<code>$1</code>")
      .replace(/\n/g, "<br/>");

    const plainText = previewText.replace(/<.*?>/g, "");

    if (plainText.length > maxLength) {
      let truncatedText = plainText.substring(0, maxLength) + "...";

      truncatedText = truncatedText
        .replace(/\*(.*?)\*/g, "<b>$1</b>")
        .replace(/_(.*?)_/g, "<i>$1</i>")
        .replace(/~(.*?)~/g, "<s>$1</s>")
        .replace(/`(.*?)`/g, "<code>$1</code>")
        .replace(/\n/g, "<br/>");

      return truncatedText;
    }

    return previewText;
  };

  const handleViewMessage = (message) => {
    setSelectedMessage(message);
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Message Details
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedMessage ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatAndTruncate(
                      selectedMessage.message,
                      selectedMessage.message.length
                    ),
                  }}
                />
              ) : (
                <p>No message selected.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex flex-column align-items-center">
        <div className="card col-12 col-md-10 col-lg-10 mx-auto mt-4 table-responsive">
          <div className="row mb-4 px-3 py-2 align-items-center">
            <div className="col d-flex align-items-center gap-3">
              <div>
                <label htmlFor="startDate" className="form-label">
                  Start Date:
                </label>
                <input
                  type="date"
                  id="startDate"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="endDate" className="form-label">
                  End Date:
                </label>
                <input
                  type="date"
                  id="endDate"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>

          <table className="table">
            <thead className="table-dark">
              <tr>
                <th scope="col">Message</th>
                <th scope="col">Sent Number</th>
                <th scope="col">Media URL</th>
                <th scope="col">Sent At</th>
                <th scope="col">Options</th>
              </tr>
            </thead>
            <tbody>
              {messageHistory.map((message) => (
                <tr key={message._id}>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: formatAndTruncate(message.message, 30),
                    }}
                  />
                  <td>{message.number}</td>
                  <td>
                    {message.mediaUrl ? (
                      <a href={message.mediaUrl} target="_blank" rel="noreferrer">
                        Click here to view
                      </a>
                    ) : (
                      "No media URL"
                    )}
                  </td>
                  <td>
                    {format(
                      parse(message.sentAt, "yyyy-MM-dd HH:mm:ss", new Date()),
                      "yyyy MMMM dd, hh:mm:ss a"
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => handleViewMessage(message)}
                      className="rounded-circle bg-dark border-0 p-2"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <MdRemoveRedEye color="white" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="m-auto">
            <nav>
              <ul className="pagination justify-content-center">
                {startPage > 1 && (
                  <li onClick={() => setPage(startPage - 1)} className="page-item">
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="page-link"
                      href="/"
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                )}

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <li
                    key={startPage + index}
                    onClick={() => setPage(startPage + index)}
                    className={`page-item ${startPage + index === page ? "active" : ""}`}
                  >
                    <a onClick={(e) => e.preventDefault()} className="page-link" href="/">
                      {startPage + index}
                    </a>
                  </li>
                ))}

                {endPage < totalPages && (
                  <li onClick={() => setPage(endPage + 1)} className="page-item">
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="page-link"
                      href="/"
                      aria-label="Next"
                    >
                      <span className="sr-only">Next</span>
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageHistory;
