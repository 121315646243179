import React from "react";
import { Routes, Route } from "react-router-dom";

import ClientTopbar from "components/ClientTopbar";
import Dashboard from "./ClientDashboard";
import Templates from "./template/Templates";
import TopupHistory from "./TopupHistory";
import Settings from "./Settings";
import MessageHistory from "./MessageHistory";

const Panel = () => {
  return (
    <div>
      <ClientTopbar />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/templates/*" element={<Templates />} />
        <Route path="/topup-history" element={<TopupHistory />} />
        <Route path="/message-history" element={<MessageHistory />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </div>
  );
};

export default Panel;
