import useAuthentication from "hooks/useAuthentication";
import React from "react";

const Settings = () => {
  const { changePassword } = useAuthentication();

  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword(oldPassword, newPassword).then(() => {
      setOldPassword("");
      setNewPassword("");
    });
  };

  return (
    <div className="container mt-3">
      <div className="card mx-auto" style={{ maxWidth: "400px" }}>
        <form onSubmit={handleSubmit}>
          <div className="card-body">
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Change Password
              </label>
              <input
                className="form-control mb-2"
                id="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Current Password"
                required
                type="password"
              />
              <input
                className="form-control mb-3"
                id="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
                required
                type="password"
              />
              <button
                disabled={oldPassword === "" || newPassword === ""}
                className="btn btn-success w-100"
              >
                Update Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
