import { create } from "zustand";

const userStore = create()((set) => ({
  clientId: undefined,
  credits: undefined,
  whatsappLoggedIn: false,
  whatsappNumber: undefined,
  accountType: "",
  setClientId: (clientId) => {
    set({ clientId });
  },
  setCredits: (credits) => {
    set({ credits });
  },
  setWhatsappLoggedIn: (whatsappLoggedIn) => {
    set({ whatsappLoggedIn });
  },
  setWhatsappNumber: (whatsappNumber) => {
    set({ whatsappNumber });
  },
  setAccountType: (accountType) => {
    set({ accountType });
  },
}));

export default userStore;
