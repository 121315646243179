import React from "react";
import NavBar from "./NavBar";
import { IoBarChartSharp, IoAlbumsSharp } from "react-icons/io5";
import useClient from "hooks/useClient";
import { useLocation } from "react-router-dom";
import useAuthentication from "hooks/useAuthentication";
import { MdHistory, MdOutlineMessage, MdSettings } from "react-icons/md";

const ClientTopbar = () => {
  const { watchClient } = useClient();
  const { getInstance } = useAuthentication();
  const pathName = useLocation().pathname;

  React.useEffect(() => {
    getInstance();
    watchClient();

    const intervalId = setInterval(() => {
      watchClient();
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavBar.Container>
      <NavBar.Item
        to={"/client/dashboard"}
        icon={<IoBarChartSharp />}
        label="Dashboard"
        active={pathName.includes("/dashboard")}
      />
      <NavBar.Item
        to={"/client/templates"}
        icon={<IoAlbumsSharp />}
        label="Templates"
        active={pathName.includes("/templates")}
      />
      <NavBar.Item
        to={"/client/topup-history"}
        icon={<MdHistory />}
        label="Topup History"
        active={pathName.includes("/topup-history")}
      />
      <NavBar.Item
        to={"/client/message-history"}
        icon={<MdOutlineMessage />}
        label="Message History"
        active={pathName.includes("/message-history")}
      />
      <NavBar.Item
        to={"/client/settings"}
        icon={<MdSettings />}
        label="Settings"
        active={pathName.includes("/settings")}
      />
    </NavBar.Container>
  );
};

export default ClientTopbar;
