import Spacer from "components/Spacer";
import { checkUserIsSignedIn } from "helpers/helperFunctions";
import useAuthentication from "hooks/useAuthentication";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { registerUser } = useAuthentication();
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [role, setRole] = React.useState(1);
  const [referrerOf, setReferrerOf] = React.useState(undefined);
  const [termsAndConditionsAgree, setTermsAndConditionsAgree] = React.useState(false);

  const handleUserPage = () => {
    const isSignedIn = checkUserIsSignedIn();

    if (isSignedIn) {
      navigate("/");
    }
  };

  React.useEffect(() => {
    queryParams.get("referrer") && setReferrerOf(queryParams.get("referrer"));
    handleUserPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!termsAndConditionsAgree) {
      alert("Please agree to the terms and conditions");
      return;
    }

    registerUser(username, email, contactNumber, password, role, referrerOf);
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card col-sm col-md-6 col-lg-4  mx-auto">
        <div className="card-body d-flex flex-column justify-center align-center">
          <img src={require("assets/images/hero-trans.png")} style={styles.logo} alt="logo" />
          <br />
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="inputUsername" className="form-label">
                Username
              </label>
              <input
                onChange={(e) => setUsername(e.target.value)}
                className="form-control"
                id="inputUsername"
                value={username}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputEmail" className="form-label">
                Email address
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control"
                id="inputEmail"
                value={email}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputContact" className="form-label">
                Contact number (07xxxxxxxxx)
              </label>
              <input
                onChange={(e) => setContactNumber(e.target.value)}
                className="form-control"
                id="inputContact"
                value={contactNumber}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputPassword" className="form-label">
                Password
              </label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="form-control"
                id="inputPassword"
                value={password}
              />
            </div>

            <div className="form-check mb-3">
              <input
                onChange={(e) => setTermsAndConditionsAgree(e.target.checked)}
                type="checkbox"
                className="form-check-input"
                id="termsAndConditionsAgree"
                value={termsAndConditionsAgree}
              />
              <label className="form-check-label" htmlFor="termsAndConditionsAgree">
                I agree to the{" "}
                <a
                  target="_blank"
                  href="https://whatsflow.xyz/terms-of-use.html"
                  className="link-primary"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>
              </label>
            </div>

            <button type="submit" className="btn btn-success w-100" disabled={!email || !password}>
              Register
            </button>

            <Spacer height={20} />
            <div style={styles.bottomContainer}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/login");
                }}
                href="/"
                className="link-success"
              >
                Already have an account? Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;

const styles = {
  logo: {
    width: 250,
    height: 100,
    alignSelf: "center",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
  },
};
