import { useLocation, useNavigate } from "react-router-dom";

import { useHttp } from "./useHttp";
import appStore from "store/appStore";
import useAlertEmmiter from "./useAlertEmmiter";
import Cookies from "js-cookie";
import userStore from "store/userStore";
import { roleRoutes } from "constants/roles";

const useAuthentication = () => {
  const navigate = useNavigate();
  const pathName = useLocation().pathname;
  const { http, protectedHttp } = useHttp();
  const { emitError, emitSuccess } = useAlertEmmiter();
  const { setLoading } = appStore();
  const { setWhatsappLoggedIn, setWhatsappNumber, setClientId, setAccountType } = userStore();

  const getInstance = async () => {
    try {
      const isSignedIn = !!Cookies.get("u_token");

      if (!isSignedIn) {
        navigate("/login");
        return;
      }

      const { data } = await protectedHttp.get("/auth/get-instance");
      const role = data.role;
      const accountType = data.accountType;

      setAccountType(accountType);

      const targetRoute = roleRoutes[role];

      if (isSignedIn && targetRoute) {
        if (pathName === targetRoute || !pathName.includes(targetRoute)) {
          navigate(`${targetRoute}/dashboard`);
        }
      }
    } catch (error) {
      emitError(error);
    }
  };

  const registerUser = async (username, email, contactNumber, password, role, referrerOf) => {
    try {
      setLoading(true);
      await http.post("/auth/register", {
        username,
        email,
        contactNumber,
        password,
        role,
        referrerOf,
      });
      navigate("/login?email=" + email);
      emitSuccess("Successfully registered, Please login!");
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const loginUser = async (email, password) => {
    try {
      setLoading(true);
      const res = await http.post("/auth/login", { email, password });
      Cookies.set("u_token", res.data.token, { expires: 30, secure: true });

      const role = res.data.role;

      const targetRoute = roleRoutes[role];

      if (res.data.client && role === 1) {
        const { clientId, whatsappLoggedIn, whatsappNumber } = res.data.client;
        setClientId(clientId);
        setWhatsappLoggedIn(whatsappLoggedIn);
        setWhatsappNumber(whatsappNumber);
        navigate("/");
      } else {
        if (targetRoute) {
          navigate(`${targetRoute}/dashboard`);
        }
      }
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    if (window.confirm("Are you sure to logout?")) {
      setLoading(true);
      protectedHttp
        .post("/auth/logout")
        .then(() => {
          Cookies.remove("u_token");
          navigate("/login");
        })
        .catch((err) => console.log(err.response.data.msg))
        .finally(() => setLoading(false));
    }
  };

  const changePassword = async (oldPassword, newPassword) => {
    try {
      setLoading(true);
      await protectedHttp.post("/auth/change-password", {
        oldPassword,
        newPassword,
      });
      emitSuccess("Password changed successfully!");
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  return { getInstance, loginUser, registerUser, signOut, changePassword };
};

export default useAuthentication;
